<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <!-- create form start -->
        <v-card>
          <div style="padding: 2%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Title"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: -1%">
                  <v-select
                    v-model="city"
                    :items="cityOptions"
                    attach
                    chips
                    label="Cities"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="minimumSalary"
                    :options="minimumSalariesOptions"
                    size="sm"
                    class="mt-3"
                    :rules="titleRules"
                    required
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="maximumSalary"
                    :options="maximumSalariesOptions"
                    size="sm"
                    class="mt-3"
                    :rules="titleRules"
                    required
                  ></b-form-select>
                </div>
              </div>

              <div class="row" style="margin-top: 4%">
                <!-- <div class="col-md-3">
                  <b-form-select
                    v-model="jobType"
                    :options="jobTypesOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div> -->
                <div class="col-md-3" style="margin-top: -1%">
                  <v-select
                    v-model="jobType"
                    :items="jobTypesOptions"
                    attach
                    chips
                    label="Job Type"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="jobFunction"
                    :options="jobFunctionsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <!-- <div class="col-md-3">
                  <b-form-select
                    v-model="experience"
                    :options="experienceOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div> -->
                <div class="col-md-3" style="margin-top: -1%">
                  <v-select
                    v-model="experience"
                    :items="experienceOptions"
                    attach
                    chips
                    label="Experience"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="education"
                    :options="educationOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
              </div>

              <div class="row" style="margin-top: 5%">
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="minimumExperience"
                    type="number"
                    label="Minimum experience"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-select
                    v-model="genders"
                    :items="gendersOptions"
                    attach
                    chips
                    label="Gender"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="noOfVacancies"
                    type="number"
                    label="No. of vacancies"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="applyBy"
                    label="Apply by"
                    prepend-icon="mdi-calendar"
                    type="date"
                  ></v-text-field>
                </div>
              </div>

              <div
                class="row"
                style="margin-top: 4%; padding-left: 1%; padding-right: 1%"
              >
                <label>Description</label>
                <vue-editor
                  style="width: 100%"
                  v-model="description"
                ></vue-editor>
              </div>

              <div
                class="row"
                style="margin-top: 8%; padding-left: 1%; padding-right: 1%"
              >
                <label>Application Procedure</label>
                <vue-editor
                  style="width: 100%"
                  v-model="applicationProcedure"
                ></vue-editor>
              </div>

              <div
                class="row"
                :style="
                  index == 0
                    ? 'margin-top: 8%; '
                    : 'margin-top: 0%; ' + 'padding-left: 1%; padding-right: 1%'
                "
                v-for="(item, index) in qa"
                :key="index"
              >
                <div class="col-md-3">
                  <v-text-field
                    v-model="item.question"
                    label="Question"
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <b-form-select
                    v-model="item.type"
                    :options="qaTypes"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>

                <div class="col-md-3" style="margin-top: 1%">
                  <v-combobox
                    v-if="item.type == 'multi'"
                    v-model="item.answer"
                    label="Options"
                    multiple
                    dense
                    size="sm"
                    chips
                    class="mt-3"
                    @change="test()"
                  ></v-combobox>

                  <v-checkbox
                    v-model="item.mandatory"
                    label="Mandatory?"
                    required
                  ></v-checkbox>
                </div>

                <div class="col-md-3" style="margin-top: 1%">
                  <v-btn
                    class="
                      btn btn-sm btn-success
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="addNewQuestionRow"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index > 0"
                    class="
                      btn btn-sm btn-danger
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="removeQuestionRow(index)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </div>
              </div>

              <div style="margin-top: 5%">
                <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  @click="createJob"
                >
                  Save
                </v-btn>
                <v-btn
                  style="margin-left: 6%"
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  @click="cancel"
                >
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      search: "",
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,

      // selection options
      title: null,
      city: null,
      minimumSalary: null,
      maximumSalary: null,
      jobType: null,
      jobFunction: null,
      experience: null,
      education: null,
      minimumExperience: null,
      genders: null,
      noOfVacancies: null,
      applyBy: new Date().toISOString().substr(0, 10),
      description: null,
      applicationProcedure: null,
      qa: [{ question: "", type: null, answer: "", mandatory: false }],

      // data
      cityOptions: [],
      minimumSalariesOptions: [],
      maximumSalariesOptions: [],
      jobTypesOptions: [],
      jobFunctionsOptions: [],
      experienceOptions: [],
      educationOptions: [],
      gendersOptions: [],
      // dynamic options with label on left i.e option 1, option 2
      // cities multiple
      // job type multiple
      // experience multiple
      qaTypes: [
        { value: null, text: "Select Type" },
        { value: "radio", text: "Options: Yes, No" },
        { value: "multi", text: "Multiple Options" },
        { value: "textarea", text: "Free Text" },
      ],
      titleRules: [
        (v) => !!v || "Field is required",
        // (v) => (v && v.length <= 10) || "Title must be less than 25 characters",
      ],
      data: [],
    };
  },
  components: {
    VueEditor,
  },
  mounted() {
    this.jobPreRequisite();
  },
  methods: {
    addNewQuestionRow() {
      this.qa.push({ question: "", type: null, answer: [], mandatory: false });
    },
    removeQuestionRow(index) {
      var temp = [];
      this.qa.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.qa = temp;
    },
    cancel() {
      this.$router.push({ name: "jobs" });
    },

    jobPreRequisite() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-pre-requisite`)
        .then((res) => {
          this.cityOptions = res.data.cities;
          // this.cityOptions.unshift(...[{ value: null, text: "choose city" }]);

          this.minimumSalariesOptions = res.data.minimumSalaries;
          this.minimumSalariesOptions.unshift(
            ...[{ value: null, text: "minimum salary" }]
          );

          this.maximumSalariesOptions = res.data.maximumSalaries;
          this.maximumSalariesOptions.unshift(
            ...[{ value: null, text: "maximum salary" }]
          );

          this.jobTypesOptions = res.data.jobTypes;
          // this.jobTypesOptions.unshift(...[{ value: null, text: "job type" }]);

          this.jobFunctionsOptions = res.data.jobFunctions;
          this.jobFunctionsOptions.unshift(
            ...[{ value: null, text: "job function" }]
          );

          this.experienceOptions = res.data.experience;
          // this.experienceOptions.unshift(
          //   ...[{ value: null, text: "experience" }]
          // );

          this.educationOptions = res.data.education;
          this.educationOptions.unshift(
            ...[{ value: null, text: "education" }]
          );

          this.gendersOptions = res.data.genders;
          //   this.gendersOptions.unshift(...[{ value: null, text: "gender" }]);

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createJob() {
      if (
        this.title == null ||
        (this.title == "" && this.city == null) ||
        (this.city == "" && this.minimumSalary == null) ||
        (this.minimumSalary == "" && this.maximumSalary == null) ||
        (this.maximumSalary == "" && this.jobType == null) ||
        (this.jobType == "" && this.jobFunction == null) ||
        (this.jobFunction == "" && this.education == null) ||
        (this.education == "" && this.minimumExperience == null) ||
        (this.minimumExperience == "" && this.genders == null) ||
        (this.genders == "" && this.noOfVacancies == null) ||
        (this.noOfVacancies == "" && this.applyBy == null) ||
        (this.applyBy == "" && this.description == null) ||
        (this.description == "" && this.applicationProcedure == null) ||
        this.applicationProcedure == ""
      ) {
        Swal.fire({
          title: "",
          text: "Please, fill all fields!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/jobs`, {
          user_id: 1,
          job_title: this.title,
          location_city: this.city,
          salary_min: this.minimumSalary,
          salary_max: this.maximumSalary,
          job_type: this.jobType,
          job_function: this.jobFunction,
          experience: this.experience,
          minimum_experience_years: this.minimumExperience,
          education: this.education,
          gender: this.genders,
          no_of_vacancies: this.noOfVacancies,
          apply_by: this.applyBy,
          job_description: this.description,
          application_procedure: this.applicationProcedure,
          questions: this.qa,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: "Job posted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>
